/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductieStaat } from '../../models/ProductieStaat';
import { SocketConnection } from '../../sockets/socket.connections';
import { Order } from '../../models/Order';
import { Product } from '../../models/Product';
import { ProductieStaatConfigurationVersion } from '../../models/ProductieStaatConfigurationVersion';
import { Service } from '../../models/Service';
import { RackSlot } from '../../models/RackSlot';
import { Rack } from '../../models/Rack';

/**
 * Api services for the `ProductieStaat` model.
 */
@Injectable()
export class ProductieStaatApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler,
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation order.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public getOrder(
    id: any,
    refresh: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/order';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Fetches belongsTo relation product.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public getProduct(
    id: any,
    refresh: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/product';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Fetches belongsTo relation version.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public getVersion(
    id: any,
    refresh: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/version';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Fetches belongsTo relation service.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public getService(
    id: any,
    refresh: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/service';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Find a related item by id for racks.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for racks
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public findByIdRacks(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Delete a related item by id for racks.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for racks
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdRacks(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Update a related item by id for racks.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for racks
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public updateByIdRacks(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Add a related item by id for racks.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for racks
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public linkRacks(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Remove the racks relation to an item by id.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for racks
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkRacks(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Check the existence of racks relation to an item by id.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for racks
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public existsRacks(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Find a related item by id for rackSlots.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for rackSlots
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public findByIdRackSlots(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Delete a related item by id for rackSlots.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for rackSlots
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdRackSlots(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Update a related item by id for rackSlots.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {any} fk Foreign key for rackSlots
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public updateByIdRackSlots(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries racks of ProductieStaat.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public getRacks(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in racks of this model.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public createRacks(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Deletes all racks of this model.
   *
   * @param {any} id ProductieStaat id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteRacks(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Counts racks of ProductieStaat.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countRacks(
    id: any,
    where: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries rackSlots of ProductieStaat.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public getRackSlots(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in rackSlots of this model.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public createRackSlots(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Deletes all rackSlots of this model.
   *
   * @param {any} id ProductieStaat id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteRackSlots(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Counts rackSlots of ProductieStaat.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countRackSlots(
    id: any,
    where: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public patchOrCreate(
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten';
    let _routeParams: any = {};
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` -
   *
   *  - `state` – `{boolean}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public setProductionStateInUse(
    id: any,
    state: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/setProductionStateInUse';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof state !== 'undefined' && state !== null)
      _urlParams.state = state;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Patches the models which match the given where
   *
   * @param {object} where
   *
   * @param {object} data Request data.
   *
   *  - `status` – `{string}` -
   *
   *  - `started` – `{boolean}` -
   *
   *  - `finished` – `{boolean}` -
   *
   *  - `rejected` – `{boolean}` -
   *
   *  - `factoryWorker` – `{FactoryWorker}` -
   *
   *  - `types` – `{string}` -
   *
   *  - `factoryWorkerMarvin` – `{string}` -
   *
   *  - `req` – `{object}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public updateStatus(
    where: any = {},
    status: any,
    started: any,
    finished: any,
    rejected: any,
    factoryWorker: any = {},
    types: any = {},
    factoryWorkerMarvin: any = {},
    req: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/updateStatus';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    if (typeof status !== 'undefined' && status !== null)
      _urlParams.status = status;
    if (typeof started !== 'undefined' && started !== null)
      _urlParams.started = started;
    if (typeof finished !== 'undefined' && finished !== null)
      _urlParams.finished = finished;
    if (typeof rejected !== 'undefined' && rejected !== null)
      _urlParams.rejected = rejected;
    if (typeof factoryWorker !== 'undefined' && factoryWorker !== null)
      _urlParams.factoryWorker = factoryWorker;
    if (typeof types !== 'undefined' && types !== null)
      _urlParams.types = types;
    if (
      typeof factoryWorkerMarvin !== 'undefined' &&
      factoryWorkerMarvin !== null
    )
      _urlParams.factoryWorkerMarvin = factoryWorkerMarvin;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Find items for the given status and date which still need to be produced.
   *
   * @param {string} status The status for which to fetch the metrics. Allowed values are: "saw", "drill", "compile", "assemble", "final_check", "orderpicking", "glass_cutter", "load".
   *
   * @param {Date} date The date which will used to query the doors which match or precede the given status date.
   *
   * @param {number} page The page which will be returned.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public getMetrics(
    status: any,
    date: any = {},
    page: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/metrics';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof status !== 'undefined' && status !== null)
      _urlParams.status = status;
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    if (typeof page !== 'undefined' && page !== null) _urlParams.page = page;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Runs the Exact finish production procedure
   *
   * @param {string} id Service id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public runExactFinishProductionProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/exactFinishProductionProcedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Find sorted production states
   *
   * @param {string} type Type of the flow
   *
   * @param {string} searchText Search text
   *
   * @param {string} filterDoorTypes Filter on door types
   *
   * @param {string} filterDoorOptions Filter on door options
   *
   * @param {string} filterStatusTypes Filter on status types
   *
   * @param {string} filterFrameColors Filter on frame colors
   *
   * @param {string} filterBusses Filter on busses
   *
   * @param {string} dateFrom Date from
   *
   * @param {string} dateUntil Date until
   *
   * @param {number} skip Skip query
   *
   * @param {number} limit Limit query
   *
   * @param {boolean} isCount Count the total returned amount of rows
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public findSorted(
    type: any,
    searchText: any = {},
    filterDoorTypes: any = {},
    filterDoorOptions: any = {},
    filterStatusTypes: any = {},
    filterFrameColors: any = {},
    filterBusses: any = {},
    dateFrom: any = {},
    dateUntil: any = {},
    skip: any,
    limit: any,
    isCount: any = {},
    customHeaders?: Function,
  ): Observable<ProductieStaat[]> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/findSorted';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    if (typeof searchText !== 'undefined' && searchText !== null)
      _urlParams.searchText = searchText;
    if (typeof filterDoorTypes !== 'undefined' && filterDoorTypes !== null)
      _urlParams.filterDoorTypes = filterDoorTypes;
    if (typeof filterDoorOptions !== 'undefined' && filterDoorOptions !== null)
      _urlParams.filterDoorOptions = filterDoorOptions;
    if (typeof filterStatusTypes !== 'undefined' && filterStatusTypes !== null)
      _urlParams.filterStatusTypes = filterStatusTypes;
    if (typeof filterFrameColors !== 'undefined' && filterFrameColors !== null)
      _urlParams.filterFrameColors = filterFrameColors;
    if (typeof filterBusses !== 'undefined' && filterBusses !== null)
      _urlParams.filterBusses = filterBusses;
    if (typeof dateFrom !== 'undefined' && dateFrom !== null)
      _urlParams.dateFrom = dateFrom;
    if (typeof dateUntil !== 'undefined' && dateUntil !== null)
      _urlParams.dateUntil = dateUntil;
    if (typeof skip !== 'undefined' && skip !== null) _urlParams.skip = skip;
    if (typeof limit !== 'undefined' && limit !== null)
      _urlParams.limit = limit;
    if (typeof isCount !== 'undefined' && isCount !== null)
      _urlParams.isCount = isCount;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result.pipe(
      map((instances: Array<ProductieStaat>) =>
        instances.map(
          (instance: ProductieStaat) => new ProductieStaat(instance),
        ),
      ),
    );
  }

  /**
   * Finish a productiestaat line
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Instance id
   *
   *  - `status` – `{string}` - Productiestaat status
   *
   *  - `body` – `{object}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public finishLine(
    id: any,
    status: any,
    body: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/finish-line/:status';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      body: body,
    };
    let _urlParams: any = {};
    if (typeof status !== 'undefined' && status !== null)
      _urlParams.status = status;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in racks of this model.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public createManyRacks(
    id: any,
    data: any[] = [],
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/racks';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in rackSlots of this model.
   *
   * @param {any} id ProductieStaat id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ProductieStaat` object.)
   * </em>
   */
  public createManyRackSlots(
    id: any,
    data: any[] = [],
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/ProductieStaten/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductieStaat`.
   */
  public getModelName() {
    return 'ProductieStaat';
  }
}
